import React, { useEffect } from "react";
import logo from "../../assets/img/logo.png"; // Replace with the correct path to your logo
import { Link, NavLink } from "react-router-dom";
import bars from "../../assets/img/bars.png";

// Define the styles for the logo
const styles = {
  logo: {
    width: '200px', // Adjust width as needed
    height: 'auto',
  },
};

const Nav = () => {
  const [show, setShow] = React.useState(false); // Default menu state is closed for mobile
  const [screenSize, setScreenSize] = React.useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);

    // Add event listener to track screen size
    window.addEventListener("resize", handleResize);

    // Automatically show the menu on desktop
    if (screenSize >= 768) {
      setShow(true);
    } else {
      setShow(false);
    }

    // Cleanup event listener
    return () => window.removeEventListener("resize", handleResize);
  }, [screenSize]);

  const toggleMenu = () => {
    if (screenSize < 768) {
      setShow(!show);
    }
  };

  return (
    <nav className="py-5 relative z-20">
      <div className="container">
        <div className="flex justify-between items-center gap-3">
          {/* Logo with NavLink */}
          <NavLink to="/" className="flex items-center gap-3">
            <img src={logo} alt="Safe Edges Logo" style={styles.logo} />
          </NavLink>
          <button
            onClick={toggleMenu}
            className="btn md:hidden block"
          >
            <img src={bars} alt="Toggle Menu" />
          </button>
          <div
            className={`menu-items transition-[0.5s] items ${
              show || screenSize >= 768
                ? "translate-x-0 flex"
                : "translate-x-[-100%] hidden"
            } justify-end items-center gap-[10px] bg-[#252525] md:bg-transparent px-[18px] py-[15px] rounded-[20px] md:static md:flex-row md:translate-x-0`}
          >
            <Link
              to="/"
              onClick={toggleMenu}
              className="text-white block py-[20px] px-[35px] rounded-[20px] hover:bg-[#0e0e0f]"
            >
              Home
            </Link>
            <Link
              to="/team"
              onClick={toggleMenu}
              className="text-white block py-[20px] px-[35px] rounded-[20px] hover:bg-[#0e0e0f]"
            >
              Our Team
            </Link>
            <Link
              onClick={toggleMenu}
              to="https://safe-edges.medium.com/"
              className="text-white block py-[20px] px-[35px] rounded-[20px] hover:bg-[#0e0e0f]"
            >
              Blogs
            </Link>
            <Link
              to="https://calendly.com/safeedges/30min"
              onClick={toggleMenu}
              className="text-white block py-[20px] px-[35px] rounded-[20px] hover:bg-[#0e0e0f]"
            >
              Schedule Meeting
            </Link>
            <Link
              to="/contact"
              onClick={toggleMenu}
              className="text-white block py-[20px] px-[35px] rounded-[20px] bg-[#0e0e0f]"
            >
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
