import React from "react";
import arrow from "../../assets/img/arrow.png";
import t1 from "../../assets/img/t1.png";
import t2 from "../../assets/img/t2.png";
import t3 from "../../assets/img/t3.png";
import t4 from "../../assets/img/t4.png";
import t5 from "../../assets/img/t5.png";
import t6 from "../../assets/img/hhh.jpeg";
import t7 from "../../assets/img/t7.png";
import frame from "../../assets/img/frame.png";
// import dp from "../../assets/img/dp.png";
import pp from "../../assets/img/pp.png";

const TeamHero = () => {
  const data = [
      // {
      //   id: 1,
      //   img: t1,
      //   name: "piyushshukla Kadis",
      //   designation: "Data Scientist Specializing in AI, ML, and Blockchain",
      //   description: (
      //     <>
      //       <p className="my-10 text-white text-[16px] md:text-xl">
      //         I am a seasoned entrepreneur and technology leader with a profound expertise in driving innovative projects and leading high-performing teams. As the Founder of SafeEdges, I've steered the company to the forefront of technology and business
      //       </p>
      //       <p className="mb-10 tetx-white text-[16px] md:text-xl">
      //         solutions, managing crucial aspects like R&D, development, information analysis, and security audits. My role extends into strategic financial oversight, vision crafting, and DevOps management, embodying a holistic approach to business leadership.
      //       </p>
      //       <p className="mb-10 tetx-white text-[16px] md:text-xl">
      //         My journey includes pivotal roles in business development and technology, where I've consistently championed growth and strategic expansion. My expertise in fostering investor relations, conducting detailed market and property analyses, and identifying novel market opportunities has been instrumental in driving businesses forward.
      //       </p>
      //       <p className="mb-10 tetx-white text-[16px] md:text-xl">
      //         In the realm of software and digital solutions, my tenure as a Software Specialist and Regional Manager underscores my adeptness in both technical and managerial capacities. I excel in remote team management and digital communication, reflecting the modern demands of global business operations.
      //       </p>
      //       <p className="mb-10 tetx-white text-[16px] md:text-xl">
      //         At the core of my professional ethos is a commitment to innovation, strategic growth, and leadership. My versatile experience across sectors such as technology, real estate, and digital communities showcases my adaptability and foresight in navigating and excelling in dynamic business landscapes.
      //       </p>
      //     </>
      //   ),
      // },
      {
        id: 1,
        img: t6,
        name: "Piyush Shukla",
        designation: "Founder of SafeEdges | Top-Ranked Security Researcher | Award-Winning Threat Hunter",
        description: (
          <>
            <p className="my-10 text-white text-[16px] md:text-xl">
            Piyush Shukla is a leading cybersecurity expert and founder of SafeEdges, recognized as a top-ranked researcher on Hackenproof and Immunefi. With extensive experience in Fuel, Sway, Rust, Solidity, and Move, he specializes in smart contract security and blockchain infrastructure. His award-winning achievements as a threat hunter underscore his commitment to ethical hacking and his impact on the cybersecurity landscape          </p>
  
          </>
        ),
      },
      {
        id: 2,
        img: t2,
        name: "Ashmeet Singh",
        designation: "Head of Blockchain: Top Researcher in Blockchain Technologies AI and ML",
        description: (
          <>
            <p className="my-10 text-white text-[16px] md:text-xl">
            Ashmeet Singh, Head of Blockchain at Safe Edges, excels in blockchain and cybersecurity. With over five years of Python and machine learning experience, he has developed blockchain projects like a Coin Market Cap Clone and an NFT Marketplace using Ethereum, Polygon, and Solidity.            </p>

          </>
        ),
      },
      {
        id: 3,
        img: t3,
        name: "Vaibhav Sethi",
        designation: "Head of Business Development | Ex Spearbit",
        description: (
          <>
            <p className="my-10 text-white text-[16px] md:text-xl">
            Vaibhav Sethi leads client engagement and business growth at Safe Edges. With a focus on managing client needs and fostering partnerships, he ensures seamless coordination, delivering customized solutions that solidify Safe Edges’ position as a trusted name in blockchain security.            </p>

          </>
        ),
      },
      // {
      //   id: 4,
      //   img: t4,
      //   name: "Ian mccann",
      //   designation: "Honored White-hat Hacker Specializing in Blockchain Security",
      //   description: (
      //     <>
      //       <p className="my-10 text-white text-[16px] md:text-xl">
      //         Ian McCann, a seasoned Data Scientist, has a rich background in Mathematics, Physics, and Programming. His academic path includes a Masters in Computational Science and a Bachelors in Physics and Applied Mathematics, leading to an intensive Data Science Immersive program at Galvanize.
      //       </p>
      //       <p className="mb-10 tetx-white text-[16px] md:text-xl">
      //         In his professional career, Ian made significant strides at Conversica over five years, focusing on ML and AI. He was instrumental in developing deep learning frameworks and automating data processes, integrating LLMs and the GPT-3 API for advanced applications.
      //       </p>
      //       <p className="mb-10 tetx-white text-[16px] md:text-xl">
      //         Ian's entrepreneurial spirit shone in the web3 space, where he not only created PostThread, a decentralized social media platform, but also founded Unstable Labs, developing a text-to-image NFT generator with Stable Diffusion. His innovative efforts were further recognized when he won first place in a Polkadot hackathon, highlighting his prowess in Blockchain technology.
      //       </p>
      //       <p className="mb-10 tetx-white text-[16px] md:text-xl">
      //         Ian's passion for AI, ML, and Blockchain drives his continuous contributions to the technological revolution, making him a notable figure in these fields.
      //       </p>

      //     </>
      //   ),
      // },
      {
        id: 5,
        img: t5,
        name: "Mohit Yadav",
        designation: " Blockchain Security Researcher - Safe Edges",
        description: (
          <>
            <p className="my-10 text-white text-[16px] md:text-xl">
            Security Researcher at Safe Edges,  specialize in vulnerability assessments and in-depth code reviews to ensure the highest security standards. My prior role as a Market Research Analyst enhanced my analytical and strategic skills, enabling me to bridge technical expertise with insightful solutions for secure innovation.            </p>

          </>
        ),
   },
   
    // {
    //   id: 7,
    //   img: t7,
    //   name: "Ian Scarffe",
    //   designation: "Data Scientist Specializing in AI, ML, and Blockchain",
    //   description: (
    //     <>
    //       <p className="my-10 text-white text-[16px] md:text-xl">
    //         Advisor - <br />
    //         Ian Scarffe is a serial entrepreneur, investor and consultant with business experience from around the world.
    //       </p>
    //       <p className="mb-10 tetx-white text-[16px] md:text-xl">
    //         As a leading entrepreneur, Ian is on a personal mission to develop a culture of entrepreneurship, helping startups achieve their full potential as well as helping to expand existing companies.  Ian has founded ‘Binkplus’, a startup incubator in Europe.
    //       </p>
    //       <p className="mb-10 tetx-white text-[16px] md:text-xl">
    //         A leading expert in Bitcoin, Blockchain and Crypto industries, Ian is at the very heart of revolutionizing the financing industry across the globe and currently consults and advises for a range of multi-million dollar companies.
    //       </p>
    //       <p className="mb-10 tetx-white text-[16px] md:text-xl">
    //         Ian’s overall mission is to foster a society of economically independent individuals who are engaged citizens, contributing to the improvement of their communities across the world.
    //       </p>
    //       <p className="mb-10 tetx-white text-[16px] md:text-xl">
    //         Ian now focuses on the Blockchain industry and offers advice, consulting services, connections to Blockchain experts and investor relations strategies.
    //       </p>
    //       <p className="mb-10 tetx-white text-[16px] md:text-xl">
    //         Ian Scarffe has outstanding entrepreneurial skills and commercial acumen with innovation and motivation. With the capacity to work at strategic levels, Ian possess exceptional interpersonal, communication and negotiation skills.
    //       </p>
    //       <p className="mb-10 tetx-white text-[16px] md:text-xl">
    //         A passion for customer service, wealth of knowledge and vast amounts of hands on experience in almost every role, means Ian has valuable insight into millions of customers, proving him to be a valuable asset to companies across the globe.
    //       </p>
    //     </>
    //   ),
    // },
  ];

  const [activeMember, setActiveMember] = React.useState(0);
  const changeActive = (index) => {
    setActiveMember(index);
  };
  return (
    <div className="team-hero py-[75px]">
      <div className="container">
        <div className="text-center">
          <h2 className="text-white text-[60px] font-semibold">Our Team</h2>
          <p className="mb-[50px]">
            Each and every person in this amazing team is dedicated to a safer
            blockchain future. <br />
            Our dream? Non-vulnerable space
          </p>

          <img src={arrow} className="block mx-auto mb-[50px]" alt="" />

          <div className="flex justify-center items-center gap-4 md:gap-10">
            {data.map((item, index) => (
              <div
                className="item cursor-pointer"
                onClick={() => changeActive(index)}
                key={item.id}
              >
                <img src={item.img} className={`team-img rounded-full ${item.id - 1 === activeMember ? "" : "bnw"}`} alt="" />
              </div>
            ))}
          </div>

          <div className="dp relative">
            <img src={frame} className="block mx-auto" alt="" />
            <img
              src={data[activeMember].img}
              className="absolute rounded-full w-[150px] top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
              alt=""
            />
          </div>

          <div className="details relative top-[-50px]">
            <h3 className="text-xl font-semibold">{data[activeMember].name}</h3>
            <p className="text-white w-[300px] mx-auto">
              {data[activeMember].designation}
            </p>
            <img src={pp} className="block mx-auto" alt="" />

            {data[activeMember].description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamHero;
